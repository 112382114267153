body {
  font-family: 'Lato';
  color: #505055;
  line-height: 1.6rem;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
  font-weight: 500;
  text-transform: uppercase;
}

.btn {
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat';
  padding: .75rem 2.5rem;
  border-radius: 0.2rem;

  &:hover {
    color: #fff;
  }
}

.nav-link, #slide-box-fblike {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* TODO moze dać do style.php */
a {
  color: #39a1f1;
}

.header h5 {
  font-size: 16px;
}

#nav  {
  padding-right: 0;
  padding-left: 0;
}

#navbarNav  {

  .menu {
    border-top: 1px solid #e5e5e5;

    .active {

    }

    .nav-link {
      text-transform: uppercase;
      font-size: 0.80rem;
      font-family: 'Montserrat';
      padding-right: 0.2rem;
      padding-left: 0.2rem;
    }

    > li > .nav-link {
      margin-top: -1px;
    }

    .parent.current{
      position: relative;
    }
  }

  .dropdown-menu  {
    padding: 0;
  }

  .dropdown-item {
    padding-left: 0;
    padding-right: 0;

    &:active{
      background: none;
    }
  }
}

.navbar {
  .menu {
   margin-left: auto;
  }

  .links  {
    color: #7d7d7d;
    margin-top: 0.5rem;
    margin-bottom: 0.6rem;

    .fa   {
      padding-right: 10px;
    }

    a   {
      color: inherit;
    }

    p   {
      margin-bottom: 0;
    }

    .list-inline-item:first-child {
      margin-right: 2rem;
    }

  }
}

.header-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

}
/*
.dropdown:hover>.dropdown-menu {
  display: block;
}
*/
#langs {
  margin-left: 1rem;

  li {
    display: inline-block;
    margin-right: 0.4rem;

  }
  .flag-icon {
    border: 1px solid #cecece;
    line-height: 0.9rem;

    :before {
    }
  }
  .lang-code  {
    display: none;
  }

  .active {
    display: none;
  }
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;

  //max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown:hover .dropdown-menu {
  //max-height: 200px;
  opacity: 1;
}

//.navbar-expand-lg .navbar-nav .dropdown-menu  {
//  display: block;
//}



.under-slider  {
  background-color: #f7f8fa;
  padding: 1.0rem 0 0.8rem 0;
  border-bottom: 1px solid #d9dee2;

  .section-inner  {
    display: block;
    justify-content: space-between;
    align-items: center;

    p   {
      margin-bottom: 0;
    }

    .btn {
      margin: 0.2rem 0.4rem;
    }
  }

  .under-slider-text  {
    margin-bottom: 1rem;
    text-align: center;
  }
  .buttons  {
    text-align: center;
  }
}
/**
  Columny
 */
.page-sidebar-column  {

  #content  {
    width: 100%;
    display: inline-block;
    float: left;
  }

  #sidebar-column {
    width: 100%;
    display: inline-block;
  }
}
.sidebar-column-left  {

  #sidebar-column {
    margin-left: 0;
    margin-right: 5%;
  }
}

#main {
  padding: 5rem 0 3rem 0;

  p {
    color: #8e8e90;
    padding-bottom: 0.5rem;
  }

  ul {
    color: #8e8e90;
  }

  .content {

    h2 {
      position: relative;
      padding-bottom: 2.5rem;

      &:after {
        position: absolute;
        bottom: 0.9rem;
        left: 0;
        width: 20%;
        height: 0.25rem;
        content: "";
      }
    }
  }

  #content ul {
    list-style: none;
    margin-top: -10px;
    color: #7b7b7d;
  }

  #content ul li::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

}

.page #main {
  padding-top: 3rem;
}

/**
#parallax
 */
#parallax {
  background-position: center;
  color: #fff;
  position: relative;

  .container {
    height: 100%;
    padding: 1rem 0 0rem 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &:before  {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #d99777;
  }
}

.bg-gray  {
  background-color: #eef0f3;
}

.section-home-contact-data  {
  font-size: 18px;

  .itp-col {
    padding: 1rem 1rem 0.5rem 1rem;
  }

  a {
    color: inherit;
  }
}

.parallax-window {
  height: 250px;
  background: transparent;
}

.parallax-window {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/**
sidebar
 */
.sidebar  {
  padding-left: 0;

  .box  {
    list-style: none;
    margin-bottom: 2rem;
  }

  .box-title  {
    margin-bottom: 1rem; // nie wiem do czego to było
    font-size: 1.4rem;
  }
}

#footer {
  //background: url("../images/fooyrt_bg.jpg") #58585a no-repeat center ;
  background: url("../images/uklad1-footer-bg_b_min.png") no-repeat center;
  background-color: #58585a;
  color: #c5c5c5;

  .footer-column  {
    padding: 5rem 0 1rem 0;

    .box-title  {
      color: #ffffff;
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }

  }

  #footer-foo {
    border-top: 1px solid #7d7d7e;
    font-weight: 300;
    text-align: center;
    padding: 3rem 0 1rem 0;

    a   {
      color: inherit;

      &:hover {
        color: #fff;
      }
    }
  }
}

/**
#cookies
 */
#cookies  {
  display: none;
  background-color: #eef0f3;
  padding: 20px 0 10px 0;
  position: fixed;
  bottom: 0;
  color: #6f7074;
  z-index: 99;

  .container-fluid {
    max-height: 180px;
    overflow: auto;
  }

  .closecookies {
    float: right;
    max-width: 200px;
    font-size: 12px;
    white-space: normal;
    padding: .5rem 0.5rem;
    margin-left: 1rem;

  }

  p   {
   font-size: 14px;
    line-height: 1rem;
  }
}

/**
  Facebook
 */
#slide-box-fblike {
  background-color: #3b579d;
  border-radius: 3px 0 0 3px;
  position: fixed;
  right: 0;
  top: 106px;
  z-index: 15;

  &:hover {
    background-color: #334c83;
  }

  a {
    width: 42px;
    height: 42px;
    padding: 0 6px 0 0;
    color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    text-decoration: none;

    .fa {
      margin-bottom: -3px;
    }
  }
}


/**
.form-wrap
 */
.wrongVal {background-color: rgba(240, 0, 0, 0.30)}
.form-wrap  {

  .form-control {
    margin-bottom: 0.6rem;
    font-size: 0.9rem;
    padding-top: 0.4rem ;
    padding-bottom: 0.4rem ;
  }

  #captcha  {
    //margin-bottom: 0.6rem;
    max-height: 2.37rem;
    border-radius: 0.25rem;
    width: 100%;
  }
}

/**
#slider-wrap-20
 */
.carousel-item-background-image {
  //height: 100vh;
  min-height: 40vh;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.layer-mask {
  background-color: rgba(0,0,0,0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.carousel-control {
  background-color: rgba(255,255,255,0.2);
  padding: 6px 18px;
}
.carousel .desc-wrap  {
  color: #fff;


  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  min-height: 100%;
  bottom: auto;
  display: none;

  h3 {
    font-size: 2rem;
    font-weight: 600;
  }
}

.carousel-indicators {

  margin-left: auto;
  margin-right: auto;
  padding-right: 0;

  &.active {
    background: none;
  }

  li {
    width: auto;
    height: auto;
    border: 1px solid #cecdcd;
    background: none;
    border-radius: 20px;

    span {
      background-color: #cecdcd;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      display: block;
      margin: 1px;

    }
  }
}

.box-footer {

  .menu {

    .dropdown-menu  {
      display: none !important;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .nav-item {
      border-bottom: 1px dashed #717172;

      &:last-child {
        border-bottom: none;
      }


    }

    .nav-link {
      padding: 0.35rem 1rem;
      text-transform: uppercase;
      color: #b9b9b9;
      //position: relative;

      &:before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 10px;
        font-size: 0.8rem;
        padding-bottom: 2px;
        vertical-align: middle;
        font-family: "FontAwesome"; content: "\f105";
      }

      &:hover {
        color: #f3f3f3;
      }
    }


  }

  .fa {
    width: 40px;
    height: 40px;
    color: #fff;
    margin-right: 10px;
    border-radius: 3px;
    font-size: 1.4rem;
    padding-top: 8px;

    &:hover  {
      opacity: 0.8;
    }
  }
  .fa-facebook  {
    background-color: #1c6db1;
  }
  .fa-twitter  {
    background-color: #39a1f1;
  }
  .fa-youtube  {
    background-color: #9c9c9e;
  }
  .fa-instagram {
    background: #ead739;
    background: -moz-linear-gradient(45deg, #ead739 0%, #ea071b 50%, #703299 100%);
    background: -webkit-linear-gradient(45deg, #ead739 0%, #ea071b 50%, #703299 100%);
    background: linear-gradient(45deg, #ead739 0%, #ea071b 50%, #703299 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ead739', endColorstr='#703299', GradientType=1);
  }
  .fa-pinterest-square {
    background-color: #b60719;
  }

}

/**
Page
 */
#top-section  {
  min-height: 150px;
  background-position: center;
  background-size: cover;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0.7;
    background-color: #4ca6ec;
  }

  .titlewrap  {
    position: relative;
    z-index: 10;
    position: relative;
    padding-bottom: 14px;

    &:after {
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 150px;
      height: 3px;
      background-color: #fff;
      content: "";
    }
    h2  {
      margin-bottom: 0;
      font-size: 1.2rem;
    }
  }


}

/**
  breadcrumb
 */
.breadcrumb-wrap  {
  background-color: #f7f8fa;
  border-bottom: 1px solid #d9dee2;
  margin-bottom: 1rem;

  .breadcrumb {
    background: none;
    margin-bottom: 0;
    border-radius: 0;
    color: #505055;

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 0.8rem;
    }

    .breadcrumb-item + .breadcrumb-item::before {
      content: "›";
      font-size: 1.5rem;
      line-height: 1.2rem;
      padding-right: 0.8rem;
    }

    .arrow  {display: none}

    .fa   {
      padding: 0 0.5rem 0 0;
    }

    a   {
      color: #505055;
    }
  }
}

/**
uklad1 sidebox
 */
.sidebar-column {

  .box  {
    margin-bottom: 4rem;
  }

  .contact-icon {

    ul {
      list-style: none;
      padding-left: 0;

      li {
        background-color: #f7f8fa;
        font-size: 0.9rem;
        width: 100%;
        vertical-align: middle;
        border-top: 1px solid #dedfe1;
        border-right: 1px solid #dedfe1;

        display: flex;
        align-items: center;

        &:last-child  {
          border-bottom: 1px solid #dedfe1;
        }

        span {
          padding: 10px 16px;
        }

        img {
          padding: 16px 6px;
          float: left;
          min-height: 72px;

        }
      }
    }

  }
}


/**
#form-contact-wrap
 */
#form-contact-wrap {

  background-color: #f7f8fa;
  border: 1px solid #d9dee2;
  padding: 2.5rem 2rem;
  font-size: 0.95rem;

  .form-control {
    border-radius: 0;
    font-size: 1.1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .form-group-rodo  {
    padding-top: 2rem;
  }

  .captcha  {
    border: 1px solid #d9dee2;
  }

  .btn  {
    border-radius: 0;
    margin-top: 2rem;
  }
}


/**
  news-listing
 */
.news-listing {

  .item {
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #e5e5e5;


  }


  .item:last-child  {
    border: none;
  }

  .title  {
    font-size: 1.2rem;
    font-family: 'Lato';
    text-transform: none;
    line-height: 1.6rem;
    margin-bottom: 1rem;
  }

  .t_main {
    font-size: 0.9rem;
    line-height: 1.4rem;
    color: #8e8e90;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: .8rem;
    margin-bottom: .8rem;
  }

  .t_category {
    display: none;
  }

  .news-date  {
    color: #8e8e90;
    font-size: 0.9rem;
    float: left;
    margin-top: 0.1rem;
  }

  .news-more {
    padding: .40rem 1.5rem;
    float: right;
    font-weight: normal;
    font-family: 'Lato';
    font-size: 0.95rem;
  }

  .thumbwrap {
    text-align: center;

    img   {
      max-height: 220px;
    }
  }
}

.news-single  {

  .news-date  {
    margin-bottom: 1rem;
  }

  .title  {
    margin-bottom: 1.5rem;
  }

  .thumbwrap  {
    margin-bottom: 1rem;
  }

  .t_category {
    display: none;
  }
}

/**
  gallery-wrap
 */
.gallery-wrap {
  justify-content: center;
  text-align: center;

  .item {
    margin-bottom: 1rem;
    padding-left: 15px;
    padding-right: 15px;


    .img-wrap {
      position: relative;
    }

    .hover-mask {
      background: rgba(0,0,0,0.3);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      overflow:visible;
      transition: all 0.4s ease-in-out;

    }

    a:hover .hover-mask   {
      opacity: 1;
    }
  }
}


/**

  RWD

 */

@media (min-width: 320px) {

}

@media (min-width: 480px) {
  .carousel .desc-wrap  {
    display: flex;
  }

  .carousel-item-background-image {
    min-height: 400px;
  }

}

@media (min-width: 576px) {

}

@media (min-width: 768px) {
  .carousel-item-background-image {
    min-height: 600px;
  }
  .carousel  {

    .desc-wrap  {

      h3  {
      font-size: 3.5rem;
      }
    }
  }

  .under-slider {

    padding: 3.8rem 0 2.8rem 0;

    .section-inner  {
      display: flex;
    }
    .under-slider-text  {
      margin-bottom: 0;
      text-align: start;
    }
    .buttons {
      text-align: start;
    }
  }

  .section-home-contact-data {
    font-size: 18px;

    .itp-col {
      padding: 4rem 1rem 2.5rem 1rem;
    }
  }
  #top-section .titlewrap  h2  {
    font-size: 2rem;
  }

  #navbarNav .menu .active > .nav-link {
    border-top: 1px solid;
  }

  #cookies .container-fluid {
    height: auto;
  }

  a[href^="tel:"] {
    pointer-events: none;
  }
  #footer .footer-column .box-title {
    margin-bottom: 3rem;
  }
}

@media (min-width: 992px) {
  #navbarNav .menu .nav-link  {
    padding-right: 0.65rem;
    padding-left: 0.65rem;
  }

  .home #main .content .col-md-6:last-child {
    padding-left: 3rem;
  }
  .page-sidebar-column #content {
    width: 69%;
  }
  .page-sidebar-column #sidebar-column {
    width: 26%;
    margin-left: 5%;
  }

  #slide-box-fblike {
    top: 126px;
  }
}

@media (min-width: 1200px) {

}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #top-section .titlewrap {
    position: absolute;
    margin-top: 50px;
  }
}